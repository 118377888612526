<template>
  <div class="text-left">
    <BaseLoading
      style="min-height: 400px; height: 90vh;"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <!-- <BaseHeader :title="'EARN FROM REFERRALS'"></BaseHeader> -->

      <div class="my-2">
      
        <div class="row">
          <div class="col-sm-8 col-12">
            <div class="card rounded shadow">
              <div class="card-header">
                <h2 class="card-title">My Earnings</h2>
              </div>

              <div class="table-responsive">
                <table class="table text-nowrap">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Client</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-sm-4 col-12">
            <base-share></base-share>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EarnFromRefferals",
};
</script>
